import { Section } from '../../../components/Section';
import * as React from 'react';

import { Car } from './components/Car';
import { Earth } from './components/Earth';

import PreviewImage from './preview.jpg';

const ID = 'change-schools';

export const ChangeSchools = () => (
  <Section
    id={ID}
    key={ID}
    image={PreviewImage}
    title="Fahrschulwechsel"
  >
    <p>
      Du bist umgezogen oder fühlst Dich in Deiner aktuellen
      Fahrschule nicht gut aufgehoben?
    </p>
    <p className="mt-20">
      Keine Bange - Ein Fahrschulwechsel ist völlig unkompliziert. Deine absolvierten Theorie- und Fahrstunden, oder
      sogar Prüfungen verlierst Du natürlich nicht. Wir helfen gerne bei
      einem reibungslosen Wechsel, sodass Du bei uns weitermachen
      kannst, wo Du in Deiner alten Fahrschule aufgehört hast.
    </p>
    <p className="mt-20">
      Letztendlich bist Du der Einzige, der entscheiden kann, welche
      Fahrschule richtig für Dich ist.
    </p>

    <div className="d-flex flex-column align-items-center justify-content-center mt-30 overflow-hidden">
      <Car width="100px" />
      <Earth />
    </div>
  </Section>
);
